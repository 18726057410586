.map-container {
  height: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .map-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 10vh;
    min-height: 250px;
    position: relative;
  }
}
