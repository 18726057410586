body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
}

button {
  cursor: pointer;
}


button:focus {
  outline: none;
}


@media (max-width: 768px) {
  .gmnoprint a, .gmnoprint span, .gm-style-cc {
      display: none;
  }
  .gmnoprint div {
    display: none;
  }
}
